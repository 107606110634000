import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  width: 345,
  outline: "none",
};

function MyConfirmMui() {
  const cookieIs18Key = "is18";
  const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const setCookie = (cname: string, cvalue: any, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const onLoad = () => {
    if (getCookie(cookieIs18Key) === "") {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    onLoad();
  }, [null]);

  const [isVisible, setIsVisible] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  if (!isVisible) {
    return null;
  }

  const onOk = () => {
    setCookie(cookieIs18Key, true, 100);
    setIsVisible(false);
  };

  const onNotOk = () => {
    //
    setIsMessageVisible(true);
  };

  const text = isMessageVisible
    ? "Je moet 18 jaar zijn of ouder om deze website te gebruiken."
    : "Ben je ouder dan 18?";

  return (
    <Modal
      open={isVisible}
      //onClose={onNotOk}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <CardMedia
          sx={{ height: 140 }}
          image="https://images.pexels.com/photos/39351/purple-grapes-vineyard-napa-valley-napa-vineyard-39351.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Welkom bij Vinofantastico
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            {text}
          </Typography>
        </CardContent>

        <CardActions style={{ justifyContent: "flex-end" }}>
          {!isMessageVisible && (
            <>
              <Button
                variant="contained"
                onClick={onOk}
                style={{ marginRight: 5, backgroundColor: "#000" }}
              >
                Ja
              </Button>
              <Button
                variant="outlined"
                style={{ borderColor: "#000", color: "#000" }}
                onClick={onNotOk}
              >
                Nee
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </Modal>
  );

  return (
    <div id="myModal" className="ecwid-modal-backdrop">
      <div className="ecwid-modal-dialog">
        <div className="ecwid-modal-content">
          <div className="ecwid-modal-title">Welkom bij Vinofantastico</div>
          <div className="ecwid-modal-body">
            {!isMessageVisible && (
              <span id="question">Ben je ouder dan 18?</span>
            )}
            {isMessageVisible && (
              <span id="message">
                Je moet 18 jaar zijn of ouder om deze website te gebruiken.
              </span>
            )}
          </div>

          {!isMessageVisible && (
            <>
              <div id="buttons" className="ecwid-modal-footer">
                <Button
                  variant="contained"
                  onClick={onOk}
                  style={{ marginRight: 5 }}
                >
                  Ja
                </Button>
                <Button
                  variant="outlined"
                  onClick={onNotOk}
                  className="btn outline"
                >
                  Nee
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyConfirmMui;
